import React, { useState } from 'react';

import { Button, Col, Row } from 'react-bootstrap';
import { css, StyleSheet } from 'aphrodite';

import Container from 'components/shared/Container';
import GlobalProductDetailsSection from 'components/product_anomalies/GlobalProductDetailsSection';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import { PRODUCT_OUTLIERS_QA_CHANGES } from 'lib/networking/endpoints';
import ProductOutliersQATableView from './ProductOutliersQATableView';
import useGet from 'lib/hooks/useGet';

function ProductOutliersQAView() {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 1;

  const {
    data: { global_product = {}, products_with_changes = [], count = 0 } = {},
    loading,
    refetch,
  } = useGet(PRODUCT_OUTLIERS_QA_CHANGES, {
    limit: itemsPerPage,
    offset: itemsPerPage * (currentPage - 1),
  });

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <Container>
      <h3 className="font-weight-bold">Global Product Changes QA View</h3>
      <GlobalProductDetailsSection globalProduct={global_product} refetch={refetch} />
      <Row className="mt-4">
        <Col>
          <Button
            className={css(styles.button)}
            variant="link"
            onClick={() => setCurrentPage(currentPage - 1)}
            size="lg"
            disabled={loading || currentPage === 1}
          >
            &lt; Previous
          </Button>
          <Button
            className={css(styles.button)}
            variant="link"
            onClick={() => setCurrentPage(currentPage + 1)}
            size="lg"
            disabled={loading || currentPage === count}
          >
            Next &gt;
          </Button>
        </Col>
        <Col className="d-flex align-items-center justify-content-end mr-3">
          {currentPage}/ {count} Products found
        </Col>
      </Row>
      <div className={css(styles.scrollableContainer)}>
        <ProductOutliersQATableView products={products_with_changes} />
      </div>
    </Container>
  );
}

const styles = StyleSheet.create({
  scrollableContainer: {
    height: '60vh',
    overflowY: 'scroll',
  },
  button: {
    fontSize: '1em',
    padding: '0.5em 1em',
    fontWeight: 'bold',
  },
  select: {
    zIndex: 2,
  },
});

export default ProductOutliersQAView;
