import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Modal, Table } from 'react-bootstrap';
import { css, StyleSheet } from 'aphrodite';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from 'react-select';

import { areYouSure } from 'lib/utils/toast';
import {
  ATTRIBUTE_VALUES,
  DATA_SOURCE_COLUMN_VALUES,
  DATA_SOURCE_UPDATE,
} from 'lib/networking/endpoints';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import SwalDialog from 'lib/utils/toast';
import useGet from 'lib/hooks/useGet';
import usePut from 'lib/hooks/usePut';

function AttributeValueMappingModal({
  selectedOption,
  sourceAttribute,
  onHide,
  dataSource,
  refetch,
}) {
  const [columnValueMap, setColumnValueMap] = useState(
    dataSource?.column_value_map?.[sourceAttribute] || {},
  );
  const { data: { attribute_values } = {} } = useGet(
    ATTRIBUTE_VALUES,
    {
      ...(selectedOption.attributeID && { attribute_id: selectedOption.attributeID }),
      ...(selectedOption.globalAttributeID && {
        global_attribute_id: selectedOption.globalAttributeID,
      }),
    },
    !selectedOption.attributeID && !selectedOption.globalAttributeID,
  );
  const { data: { column_values } = {}, loading } = useGet(
    DATA_SOURCE_COLUMN_VALUES,
    {
      data_source_id: dataSource.id,
      column_name: sourceAttribute,
    },
    !selectedOption.attributeID && !selectedOption.globalAttributeID,
  );
  const { putData: updateDataSource } = usePut(DATA_SOURCE_UPDATE, () => {
    refetch();
    onHide();
    SwalDialog('success', `Successfully Updated the Column Value Map`, 'Success', 'center');
  });

  const attributeValueOptions = attribute_values?.map(value => ({
    value: value.attribute_value,
    label: value.attribute_value,
  }));

  const handleColumnValueMapChange = (columnValue, option) =>
    setColumnValueMap(prevState => ({
      ...prevState,
      [columnValue]: option?.value || null,
    }));
  return (
    <Modal size="xl" show onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Map Source Attribute Values</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table striped>
          <thead>
            <tr>
              <th>Source Attribute Values</th>
              <th />
              <th>FSA Attribute Values</th>
            </tr>
          </thead>
          {loading ? (
            <div className={css(styles.spinnerOverlay)}>
              <LoadingSpinner />
            </div>
          ) : (
            <tbody>
              {column_values &&
                Object.keys(column_values).map(columnValue => (
                  <tr key={columnValue}>
                    <td>{columnValue}</td>
                    <td>
                      <FontAwesomeIcon icon={faArrowRight} />
                    </td>
                    <td>
                      <Select
                        value={
                          attributeValueOptions?.find(
                            option => option.value === columnValueMap[columnValue],
                          ) || null
                        }
                        options={attributeValueOptions}
                        onChange={option => handleColumnValueMapChange(columnValue, option)}
                        isClearable
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          )}
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            areYouSure(() => {
              updateDataSource({
                data_source_id: dataSource.id,
                column_value_map: {
                  ...dataSource?.column_value_map,
                  [sourceAttribute]: columnValueMap,
                },
              });
            }, 'Are you sure You want to Save The Column Value Mapping?');
          }}
        >
          Save Source Attrubute Value Mappings
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

const styles = StyleSheet.create({
  spinnerOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1,
  },
});

AttributeValueMappingModal.propTypes = {
  onHide: PropTypes.func,
  selectedOption: PropTypes.object,
  sourceAttribute: PropTypes.string,
  dataSource: PropTypes.object,
  refetch: PropTypes.func,
};

export default AttributeValueMappingModal;
