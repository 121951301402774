import React from 'react';

import { Row } from 'react-bootstrap';
import classNames from 'classnames';
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';

import Container from 'components/shared/Container';
import { GET_SIMILAR_BRAND_NAME_INFO } from 'lib/networking/endpoints';
import pointerOnHover from 'lib/css/pointerOnHover';
import PrimaryBrandView from 'components/manage_brands/PrimaryBrandView';
import SimilarBrandDetailsView from 'components/manage_brands/SimilarBrandDetailsView';
import useGet from 'lib/hooks/useGet';

function BrandQAV2() {
  const history = useHistory();
  const {
    data: { similar_brand, products } = {},
    refetch,
    loading,
  } = useGet(GET_SIMILAR_BRAND_NAME_INFO, {});
  return (
    <Container>
      <div className="mb-3 d-flex justify-content-left">
        <h3 className="font-weight-bold mr-2">
          <FontAwesomeIcon
            className={classNames(pointerOnHover, 'mr-2')}
            icon={faArrowCircleLeft}
            onClick={() => history.push('/enrich-products/manage-brands')}
          />
          Verified Brands
        </h3>
      </div>
      <Row>
        <PrimaryBrandView brand={similar_brand?.primary_brand} loading={loading} />
      </Row>
      <Row className="d-flex justify-content-center">
        <SimilarBrandDetailsView
          similar_brand={similar_brand}
          products={products}
          refetch={refetch}
          loading={loading}
        />
      </Row>
    </Container>
  );
}

export default BrandQAV2;
