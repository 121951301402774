import React from 'react';
import PropTypes from 'prop-types';

import { css, StyleSheet } from 'aphrodite';
import classNames from 'classnames';
import { Col, Image, Row } from 'react-bootstrap';

import { anchovy, filterContainerBoarder, squidInk } from 'lib/css/colors';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import placeholderProductImage from 'images/placeholder-img-product-v2.svg';

function PrimaryBrandView({ brand, loading }) {
  return (
    <Col className={classNames(css(styles.filterContainer), 'my-2 p-3')}>
      {loading && (
        <div className={css(styles.spinnerOverlay)}>
          <LoadingSpinner />
        </div>
      )}
      <Row>
        <Col xs={2}>
          <Image
            className={css(styles.square)}
            src={brand?.logo_url || placeholderProductImage}
            alt="Brand Logo"
          />
        </Col>
        <Col>
          <div className={css(styles.brandName)}>{brand?.name}</div>
          <div className="text-muted">
            {brand?.global_manufacturer?.name && <span>by {brand?.global_manufacturer.name}</span>}
          </div>
          {brand?.keywords && brand.keywords.length > 0 && (
            <div className="text-muted">Key Words: {brand.keywords.join(', ')}</div>
          )}
        </Col>
      </Row>
    </Col>
  );
}

const styles = StyleSheet.create({
  square: {
    width: '200px',
    height: '200px',
    objectFit: 'contain',
    border: '2px solid ' + anchovy,
    marginBottom: '1rem',
    padding: '0.5rem',
  },
  brandName: {
    fontSize: 40,
    color: squidInk,
    fontWeight: 800,
  },
  keyWords: {
    fontSize: 10,
  },
  filterContainer: {
    border: `3px solid ${filterContainerBoarder}`,
    marginLeft: '10px',
    marginRight: '10px',
    width: '300px',
    height: '240px',
    overflow: 'hidden',
    position: 'relative',
  },
  spinnerOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.8)', // semi-transparent background to dim the content
    zIndex: 1,
  },
});

PrimaryBrandView.propTypes = {
  brand: PropTypes.object,
  loading: PropTypes.bool,
};

export default PrimaryBrandView;
