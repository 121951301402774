import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'react-bootstrap';
import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import { Table } from 'react-bootstrap';

import { backgroundGrey } from 'lib/css/colors';
import Container from 'components/shared/Container';
import { grape } from 'lib/css/colors';
import pointerOnHover from 'lib/css/pointerOnHover';
import ProductOutlierChangeTypes from 'lib/enums/ProductOutlierChangeTypeEnum';
import VerificationStatusIcon from 'components/global_products/detail_view/VerificationStatusIcon';

function ProductOutliersQATableView({ products }) {
  const getRowClass = changeType => {
    switch (changeType) {
      case ProductOutlierChangeTypes.VERIFY_PRODUCTS:
        return 'text-success'; // Green for "VERIFY_PRODUCTS"
      case ProductOutlierChangeTypes.UNLINK_PRODUCTS:
        return 'text-warning'; // Yellow/Orange for "UNLINK_PRODUCTS"
      case ProductOutlierChangeTypes.CREATE_NEW_PRODUCT_GROUP:
        return 'text-warning'; // Yellow/Orange for "UNLINK_PRODUCTS"
      case ProductOutlierChangeTypes.DELETE_PRODUCTS:
        return 'text-danger'; // Red for "DELETE_PRODUCTS"
      default:
        return 'text-dark'; // Black for other types
    }
  };

  return (
    <Container>
      <Table className={css(styles.tableText)}>
        <thead className={css(styles.thead)}>
          <tr>
            <th>Change Type</th>
            <th>Entity Type</th>
            <th>Entity Name</th>
            <th>SKU</th>
            <th>Product Name</th>
            <th>Core 11</th>
            <th>Pack Size</th>
            <th>Brand</th>
            <th>Change Done By</th>
            <th />
            <th />
          </tr>
        </thead>
        <tbody className={css(styles.tbody)}>
          {products?.length ? (
            products?.map((product, index) => (
              <tr key={index} className={getRowClass(product.change_type)}>
                <td>{product.change_type}</td>
                <td>{product.entity_type}</td>
                <td>{product.entity_name}</td>
                <td>{product.sku}</td>
                <td>{product.name}</td>
                <td>{product.core_11 || '-'}</td>
                <td>{product.pack_size || '-'}</td>
                <td>{product.brand_name || '-'}</td>
                <td>{product.change_done_by}</td>
                <td className="text-center">
                  <VerificationStatusIcon verificationStatus={product.verification_status} />
                </td>
                <td className="d-flex">
                  {product.global_product_id && product.id && (
                    <Button
                      variant="link"
                      className={classNames(css(styles.button), pointerOnHover)}
                      onClick={() => {
                        window.open(
                          `/global-product-detail/${product.global_product_id}/` +
                            `${product.id}/${product.entity_type.toLowerCase()}`,
                          '_blank',
                        );
                      }}
                    >
                      View
                    </Button>
                  )}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="100%" className="text-center">
                No products found.
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </Container>
  );
}

const styles = StyleSheet.create({
  tableText: {
    fontSize: '0.95rem',
  },
  thead: {
    position: 'sticky',
    top: 0,
    backgroundColor: backgroundGrey,
    zIndex: 1,
  },
  tbody: {
    position: 'relative',
  },
  button: {
    color: grape,
  },
});

ProductOutliersQATableView.propTypes = {
  products: PropTypes.array,
};

export default ProductOutliersQATableView;
