import React, { useState } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import { faPen, faParking } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form } from 'react-bootstrap';

import { anchovy } from 'lib/css/colors';
import { ATTRIBUTES_FOR_PRODUCT_UPDATE } from 'lib/networking/endpoints';
import AttributeInputType from 'lib/enums/AttributeInputType';
import CHANGE_LOG_USER_TYPE from 'lib/enums/ChangeLogUserType';
import DocumentsInputTypeView from 'components/global_products/detail_view/DocumentsInputTypeView';
import EditableAttribute from 'components/global_products/detail_view/EditableAttribute';
import { faSquareCheck } from 'images/icons/fsa-monotone-svg-icons';
import pointerOnHover from 'lib/css/pointerOnHover';
import SwalDialog from 'lib/utils/toast';
import usePost from 'lib/hooks/usePost';

function AttributeRow({
  attribute,
  productId,
  productType,
  refetch,
  refetchAttributes,
  isGlobalProductAttribute = false,
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [attributeValue, setAttributeValue] = useState(attribute.attribute_value);

  const { postData: updateAttribute } = usePost(
    ATTRIBUTES_FOR_PRODUCT_UPDATE,
    () => {
      refetch();
      refetchAttributes();
    },
    error => {
      SwalDialog(
        'error',
        'An error occurred: ' + error?.response?.data?.message,
        'Error',
        'center',
      );
    },
  );

  function humanReadableValue(attribute) {
    if (attribute.attribute_value !== null && attribute.input_type === AttributeInputType.BOOLEAN) {
      return attribute.attribute_value === '1' ? 'true' : 'false';
    } else if (attribute.input_type === AttributeInputType.DOCUMENT) {
      return <DocumentsInputTypeView strJsonObject={attribute.attribute_value} />;
    }
    return attribute.attribute_value;
  }

  return (
    <tr key={attribute.attribute_name}>
      <td>
        <div className={css(styles.attributeHeader)}>
          {`${attribute.attribute_name} 
          | ${attribute.attribute_section}
          - ${attribute.attribute_sub_section}
           ${attribute.entity_type ? `| ${attribute.entity_type}` : ''}
           ${
             attribute.data_source_type
               ? `| Source - ${attribute.data_source_type}${
                   attribute.data_source_name ? `/${attribute.data_source_name}` : ''
                 }`
               : ''
           } `}
          {attribute.is_propagated && (
            <>
              <FontAwesomeIcon className="ml-2" icon={faParking} />
              <span className="pl-1">{attribute.entity_owner}</span>
            </>
          )}
        </div>
        {isGlobalProductAttribute ? (
          <EditableAttribute
            attribute={attribute}
            isGlobalProductAttribute={isGlobalProductAttribute}
          />
        ) : (
          <div>
            {isEditing ? (
              <Form
                onBlur={() =>
                  // on document type attributes since we are showing input boxes; when click on it
                  // vanishes due to onBlur becomes active
                  attribute.input_type !== AttributeInputType.DOCUMENT && setIsEditing(false)
                }
                className={css(styles.row)}
                onSubmit={e => e.preventDefault()}
              >
                <FontAwesomeIcon
                  className={classNames(pointerOnHover, css(styles.editIcon))}
                  icon={faSquareCheck}
                  onMouseDown={() => {
                    updateAttribute({
                      product_id: productId,
                      attribute_name: attribute.attribute_name,
                      old_value: attribute.attribute_value,
                      attribute_value: attributeValue || null,
                      product_type: productType,
                      user_type: CHANGE_LOG_USER_TYPE.FSA,
                      override_with_null_value: true,
                    }).then(() => setIsEditing(false));
                  }}
                  size="lg"
                />
                <EditableAttribute
                  attribute={attribute}
                  setIsEditing={setIsEditing}
                  setAttributeValue={setAttributeValue}
                />
              </Form>
            ) : (
              <div className={css(styles.readableRow)}>
                <FontAwesomeIcon
                  className={classNames(pointerOnHover, css(styles.editIcon))}
                  icon={faPen}
                  onClick={() => {
                    setIsEditing(true);
                  }}
                />
                {humanReadableValue(attribute)}
              </div>
            )}
          </div>
        )}
      </td>
    </tr>
  );
}

const styles = StyleSheet.create({
  attributeHeader: {
    color: anchovy,
  },
  row: {
    display: 'flex',
    marginLeft: '-1.65rem',
  },
  readableRow: {
    display: 'flex',
    marginLeft: '-1.65rem',
    width: '100%',
  },
  editIcon: {
    marginRight: '0.6rem',
  },
});

AttributeRow.propTypes = {
  attribute: PropTypes.object,
  productId: PropTypes.number,
  productType: PropTypes.string,
  refetch: PropTypes.func,
  refetchAttributes: PropTypes.func,
  isGlobalProductAttribute: PropTypes.bool,
};

export default AttributeRow;
