// update along with app/lib/enums/product_verification_status.py
const ProductVerificationStatus = {
  UNVERIFIED: 'UNVERIFIED',
  VERIFIED: 'VERIFIED',
  PENDING_VERIFICATION: 'PENDING_VERIFICATION',
};

const ProductVerificationStatusOptions = [
  { value: ProductVerificationStatus.UNVERIFIED, label: 'Unverified' },
  { value: ProductVerificationStatus.VERIFIED, label: 'Verified' },
  { value: ProductVerificationStatus.PENDING_VERIFICATION, label: 'Pending Verification' },
];

export { ProductVerificationStatus, ProductVerificationStatusOptions };
