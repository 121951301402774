import React, { useEffect, useState } from 'react';

import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import { css, StyleSheet } from 'aphrodite';
import Select from 'react-select';
import Swal from 'sweetalert2';
import ToggleSwitch from 'react-switch';
import { useDebounce } from 'use-debounce';
import { useHistory } from 'react-router-dom';

import ActionDropDown from 'components/shared/ActionDropDown';
import { areYouSure } from 'lib/utils/toast';
import Container from 'components/shared/Container';
import ConvertManufacturerProductsModal from 'components/product_anomalies/ConvertManufacturerProductsModal';
import GlobalProductDetailsSection from 'components/product_anomalies/GlobalProductDetailsSection';
import { grape } from 'lib/css/colors';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import ManageProductOutliersTabView from 'components/product_anomalies/ManageProductOutliersTabView';
import MergeProductsModal from 'components/product_anomalies/MergeProductsModal';
import ProductEditModal from 'components/product_anomalies/ProductEditModal';
import ProductListFilter from 'components/shared/ProductListFilter';
import {
  PRODUCT_OUTLIERS,
  SKU_MAPPINGS_CONVERT_MANUFACTURER_PRODUCTS_OF_PRODUCT_LIST,
  SKU_MAPPINGS_CONVERT_PRODUCTS,
  SKU_MAPPINGS_DELETE_PRODUCTS,
  SKU_MAPPINGS_DELETE_UNVERIFIED_MANUFACTURER_PRODUCTS,
  SKU_MAPPINGS_UNLINK_PRODUCT_GROUP,
  SKU_MAPPINGS_UNVERIFY_LINKS,
  SKU_MAPPINGS_VERIFY_LINKS,
} from 'lib/networking/endpoints';
import ProductOutlierChangesExportModal from 'components/product_anomalies/ProductOutlierChangesExportModal';
import ProductUnlinkButton from 'components/global_products/detail_view/ProductUnlinkButton';
import { ProductVerificationStatus } from 'lib/enums/ProductVerificationStatus';
import { ProductTypeDisplay, ProductTypeDisplayOptions } from 'lib/enums/ProductType';
import UnlinkProductGroupModal from 'components/product_anomalies/UnlinkProductGroupModal';
import useGet from 'lib/hooks/useGet';
import usePost from 'lib/hooks/usePost';

function ManageProductOutliersView() {
  const history = useHistory();

  const [selectedList, setSelectedList] = useState([]);
  const [productList, setProductList] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [showOutliersOnly, setShowOutliersOnly] = useState(true);
  const [showUnlinkProductGroupModal, setShowUnlinkProductGroupModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchSKU, setSearchSKU] = useState('');
  const [searchSKUQuery] = useDebounce(searchSKU, 800);
  const [entityType, setEntityType] = useState();
  const [showMergeModal, setShowMergeModal] = useState(false);
  const [showUnverifiedOnly, setShowUnverifiedOnly] = useState(true);
  const [showProductOutlierExportModal, setShowProductOutlierExportModal] = useState(false);
  const [searchGlobalProductID, setSearchGlobalProductID] = useState('');
  const [searchGlobalProductIDQuery] = useDebounce(searchGlobalProductID, 800);
  const [productEditModalSettings, setProductEditModalSettings] = useState({ show: false });
  const [showConvertProductModal, setShowConvertProductModal] = useState(false);

  const itemsPerPage = 1;

  const {
    data: { count } = {},
    loading: loadingCount,
    refetch: refetchCount,
  } = useGet(PRODUCT_OUTLIERS, {
    product_list_id: productList?.id,
    show_outliers_only: showOutliersOnly,
    SKU_search_query: searchSKUQuery,
    global_product_id_search_query: searchGlobalProductIDQuery,
    show_unverified_only: showUnverifiedOnly,
    count_only: true,
  });

  const {
    data: { products = [], global_product = {} } = {},
    loading,
    refetch,
  } = useGet(PRODUCT_OUTLIERS, {
    limit: itemsPerPage,
    offset: itemsPerPage * (currentPage - 1),
    product_list_id: productList?.id,
    show_outliers_only: showOutliersOnly,
    SKU_search_query: searchSKUQuery,
    global_product_id_search_query: searchGlobalProductIDQuery,
    show_unverified_only: showUnverifiedOnly,
  });

  const { postData: verifyLinks, loading: linksVerifying } = usePost(
    SKU_MAPPINGS_VERIFY_LINKS,
    () => {
      Swal.fire({
        icon: 'success',
        text: 'Successfully verified',
        title: 'Success',
      });
      setSelectedList([]);
      refetch();
      refetchCount();
    },
    error =>
      Swal.fire({
        icon: 'error',
        text: error?.response?.data?.message || 'Something went wrong, please try again.',
        title: 'Error',
      }),
  );

  const { postData: unverifyLinks, loading: linksUnverifying } = usePost(
    SKU_MAPPINGS_UNVERIFY_LINKS,
    () => {
      Swal.fire({
        icon: 'success',
        text: 'Successfully unverified',
        title: 'Success',
      });
      setSelectedList([]);
      refetch();
      refetchCount();
    },
    error =>
      Swal.fire({
        icon: 'error',
        text: error?.response?.data?.message || 'Something went wrong, please try again.',
        title: 'Error',
      }),
  );

  const { postData: unlinkProductGroup, loading: unlinkingProductGroup } = usePost(
    SKU_MAPPINGS_UNLINK_PRODUCT_GROUP,
    () => {
      Swal.fire({
        icon: 'success',
        text: 'Successfully unlinked',
        title: 'Success',
      });
      setSelectedList([]);
      setShowUnlinkProductGroupModal(false);
      refetch();
      refetchCount();
    },
    error =>
      Swal.fire({
        icon: 'error',
        text: error?.response?.data?.message || 'Something went wrong, please try again.',
        title: 'Error',
      }),
  );

  const { postData: convertProducts, loading: convertingProducts } = usePost(
    SKU_MAPPINGS_CONVERT_PRODUCTS,
    () => {
      Swal.fire({
        icon: 'success',
        text: 'Successfully converted',
        title: 'Success',
      });
      setSelectedList([]);
      refetch();
      refetchCount();
    },
    error =>
      Swal.fire({
        icon: 'error',
        text: error?.response?.data?.message || 'Something went wrong, please try again.',
        title: 'Error',
      }),
  );

  const { postData: convertProductsOfProductList } = usePost(
    SKU_MAPPINGS_CONVERT_MANUFACTURER_PRODUCTS_OF_PRODUCT_LIST,
    () => {
      Swal.fire({
        icon: 'success',
        text: 'Started converting manufacturer products of product list',
        title: 'Success',
      });
      setSelectedList([]);
      refetch();
      refetchCount();
    },
    error =>
      Swal.fire({
        icon: 'error',
        text: error?.response?.data?.message || 'Something went wrong, please try again.',
        title: 'Error',
      }),
  );

  const { postData: deleteProducts, loading: deletingProducts } = usePost(
    SKU_MAPPINGS_DELETE_PRODUCTS,
    () => {
      Swal.fire({
        icon: 'success',
        text: 'Successfully deleted products',
        title: 'Success',
      });
      setSelectedList([]);
      refetch();
      refetchCount();
    },
    error =>
      Swal.fire({
        icon: 'error',
        text: error?.response?.data?.message || 'Something went wrong, please try again.',
        title: 'Error',
      }),
  );

  const { postData: deleteManufacturerProducts } = usePost(
    SKU_MAPPINGS_DELETE_UNVERIFIED_MANUFACTURER_PRODUCTS,
    () => {
      Swal.fire({
        icon: 'success',
        text: 'Started deleting unverified manufacturer products',
        title: 'Success',
      });
      setSelectedList([]);
      refetch();
      refetchCount();
    },
    error =>
      Swal.fire({
        icon: 'error',
        text: error?.response?.data?.message || 'Something went wrong, please try again.',
        title: 'Error',
      }),
  );
  const filteredProducts = products.filter(product => {
    const nameMatches = product.name.toLowerCase().includes(searchTerm.toLowerCase());
    const entityTypeMatches = !entityType || product.entity_type === entityType.label;

    return nameMatches && entityTypeMatches;
  });

  useEffect(() => {
    setCurrentPage(1);
  }, [
    productList,
    showOutliersOnly,
    showUnverifiedOnly,
    searchGlobalProductIDQuery,
    searchSKUQuery,
  ]);

  useEffect(() => {
    setSelectedList([]);
  }, [global_product?.id]);

  if (loading || convertingProducts) {
    return <LoadingSpinner />;
  }

  const handleSearch = event => {
    const searchTerm = event.target.value;
    setSearchTerm(searchTerm);
  };

  return (
    <Container>
      <div className="mb-3 d-flex justify-content-between">
        <h3 className="font-weight-bold">Global Product Linkages</h3>
        <div className="d-flex align-items-center">
          <Button
            className="mr-2"
            variant="link"
            onClick={() => history.push('/global-product-linkages/qa-product-changes')}
          >
            Global Product Changes QA
          </Button>
          <ActionDropDown
            submenuItems={[
              {
                title: 'Export',
                action: () => setShowProductOutlierExportModal(true),
              },
              {
                title: 'Delete Unverified Manufacturer Products',
                action: () =>
                  areYouSure(
                    () => deleteManufacturerProducts({ product_list_id: productList?.id }),
                    'Are you sure you want to delete unverified manufacturer products for this list?',
                  ),
                disabled: !productList,
              },
            ]}
          />
        </div>
      </div>
      <Row className="d-flex align-items-center">
        <Col md={3}>
          <ProductListFilter productList={productList} setProductList={setProductList} />
        </Col>
        <Col md={3}>
          <Form.Control
            type="text"
            onChange={event => setSearchSKU(event.target.value)}
            value={searchSKU}
            placeholder="Search by SKU"
          />
        </Col>
        <Col md={3}>
          <Form.Control
            type="text"
            onChange={event => {
              setSearchGlobalProductID(event.target.value);
            }}
            value={searchGlobalProductID}
            placeholder="Search by global product id"
          />
        </Col>
        <Col md={3} className="d-flex align-items-center">
          Show Outliers Only
          <ToggleSwitch
            className="ml-1 mr-4"
            onColor={grape}
            checked={showOutliersOnly}
            checkedIcon={false}
            uncheckedIcon={false}
            onChange={e => setShowOutliersOnly(e)}
          />
          Show Unverified Only
          <ToggleSwitch
            className="ml-1"
            onColor={grape}
            checked={showUnverifiedOnly}
            checkedIcon={false}
            uncheckedIcon={false}
            onChange={e => setShowUnverifiedOnly(e)}
          />
        </Col>
      </Row>
      <Row className="my-4 d-flex align-items-center">
        <Col className="d-flex justify-content-end mr-3">
          <Button
            className="mr-3"
            variant="danger"
            disabled={!selectedList.length || deletingProducts}
            onClick={() =>
              areYouSure(
                () =>
                  deleteProducts({
                    global_product_id: global_product.id,
                    manufacturer_product_ids: selectedList
                      .filter(product => product.entity_type === ProductTypeDisplay.MANUFACTURER)
                      .map(product => product.id),
                    vendor_product_ids: selectedList
                      .filter(product => product.entity_type === ProductTypeDisplay.DISTRIBUTOR)
                      .map(product => product.id),
                  }),
                'Are you sure you want to delete the selected products?',
              )
            }
          >
            Delete Products
          </Button>
          <Button
            className="mr-3 px-4"
            disabled={!selectedList.length || unlinkingProductGroup}
            variant="outline-danger"
            onClick={() => setShowUnlinkProductGroupModal(true)}
          >
            Create New Group
          </Button>
          <span>
            <ProductUnlinkButton
              globalProductId={global_product.id}
              manufacturerProductIds={selectedList
                .filter(product => product.entity_type === ProductTypeDisplay.MANUFACTURER)
                .map(product => product.id)}
              vendorProductIds={selectedList
                .filter(product => product.entity_type === ProductTypeDisplay.DISTRIBUTOR)
                .map(product => product.id)}
              verificationStatus={ProductVerificationStatus.UNVERIFIED}
              buttonText={'Unlink'}
              refetch={() => {
                refetch();
                setSelectedList([]);
              }}
              disabled={!selectedList.length}
              showProductUnlinkModal={selectedList.length === 1}
              setShowProductUnlinkModal={setShowUnlinkProductGroupModal}
            />
          </span>
          <Button
            className="mr-3"
            variant="outline-primary"
            disabled={
              !selectedList.length ||
              convertingProducts ||
              selectedList.some(product => product.entity_type === ProductTypeDisplay.DISTRIBUTOR)
            }
            onClick={
              productList
                ? () => setShowConvertProductModal(true)
                : () =>
                    areYouSure(
                      () =>
                        convertProducts({
                          global_product_id: global_product.id,
                          manufacturer_product_ids: selectedList
                            .filter(
                              product => product.entity_type === ProductTypeDisplay.MANUFACTURER,
                            )
                            .map(product => product.id),
                        }),
                      'Are you sure you want to convert the selected manufacturer products?',
                    )
            }
          >
            Convert to distributor products
          </Button>
          <Button
            className="mr-3"
            variant="outline-primary"
            disabled={
              selectedList.length < 2 ||
              convertingProducts ||
              selectedList.some(product => product.entity_type === ProductTypeDisplay.DISTRIBUTOR)
            }
            onClick={() => setShowMergeModal(true)}
          >
            Merge manufacturer products
          </Button>
          <Button
            className="mr-3"
            disabled={!selectedList.length || linksVerifying}
            onClick={() =>
              verifyLinks({
                global_product_id: global_product.id,
                manufacturer_product_ids: selectedList
                  .filter(product => product.entity_type === ProductTypeDisplay.MANUFACTURER)
                  .map(product => product.id),
                vendor_product_ids: selectedList
                  .filter(product => product.entity_type === ProductTypeDisplay.DISTRIBUTOR)
                  .map(product => product.id),
              })
            }
          >
            Verify Links
          </Button>
          <Button
            className="px-3"
            disabled={!selectedList.length || linksUnverifying}
            onClick={() =>
              unverifyLinks({
                global_product_id: global_product.id,
                manufacturer_product_ids: selectedList
                  .filter(product => product.entity_type === ProductTypeDisplay.MANUFACTURER)
                  .map(product => product.id),
                vendor_product_ids: selectedList
                  .filter(product => product.entity_type === ProductTypeDisplay.DISTRIBUTOR)
                  .map(product => product.id),
              })
            }
          >
            Unverify Links
          </Button>
        </Col>
      </Row>
      <GlobalProductDetailsSection globalProduct={global_product} refetch={refetch} />
      <Row className="mt-4">
        <Col>
          <Button
            className={css(styles.button)}
            variant="link"
            onClick={() => setCurrentPage(currentPage - 1)}
            size="lg"
            disabled={loading || currentPage === 1}
          >
            &lt; Previous
          </Button>
          <Button
            className={css(styles.button)}
            variant="link"
            onClick={() => setCurrentPage(currentPage + 1)}
            size="lg"
            disabled={loading || currentPage === count}
          >
            Next &gt;
          </Button>
        </Col>
        <Col>
          <Form.Control
            type="text"
            onChange={handleSearch}
            value={searchTerm}
            placeholder="Search by product name"
          />
        </Col>
        <Col>
          <Select
            className={css(styles.select)}
            isClearable
            options={ProductTypeDisplayOptions}
            value={entityType}
            onChange={option => setEntityType(option)}
            placeholder={'Select by product type'}
          />
        </Col>
        <Col className="d-flex align-items-center justify-content-end mr-3">
          {loadingCount ? (
            <Spinner animation="border" role="status" size="sm" className="mr-4" />
          ) : (
            <>
              {currentPage}/ {count} Products found
            </>
          )}
        </Col>
      </Row>
      <div className={css(styles.scrollableContainer)}>
        <ManageProductOutliersTabView
          products={filteredProducts}
          selectedList={selectedList}
          setSelectedList={setSelectedList}
          globalProductId={global_product?.id}
          setProductEditModalSettings={setProductEditModalSettings}
        />
      </div>
      {showUnlinkProductGroupModal ? (
        <UnlinkProductGroupModal
          globalProductId={global_product.id}
          selectedList={selectedList}
          unlinkProductGroup={unlinkProductGroup}
          unlinkingProductGroup={unlinkingProductGroup}
          onHide={() => {
            setShowUnlinkProductGroupModal(false);
            setSelectedList([]);
          }}
        />
      ) : null}
      {showMergeModal ? (
        <MergeProductsModal
          globalProductId={global_product.id}
          productsList={selectedList}
          setProductsList={setSelectedList}
          onHide={() => {
            setShowMergeModal(false);
            refetch();
            setSelectedList([]);
          }}
        />
      ) : null}
      {showProductOutlierExportModal ? (
        <ProductOutlierChangesExportModal
          onHide={() => {
            setShowProductOutlierExportModal(false);
          }}
        />
      ) : null}
      {productEditModalSettings.show ? (
        <ProductEditModal
          globalProductId={global_product.id}
          show={productEditModalSettings.show}
          product={productEditModalSettings.product}
          onHide={() => {
            setProductEditModalSettings({ show: false });
          }}
          refetch={refetch}
        />
      ) : null}
      {showConvertProductModal ? (
        <ConvertManufacturerProductsModal
          globalProductId={global_product.id}
          productListId={productList?.id}
          selectedProductsList={selectedList}
          convertProducts={convertProducts}
          convertProductsOfProductList={convertProductsOfProductList}
          onHide={() => {
            setShowConvertProductModal(false);
            setSelectedList([]);
          }}
        />
      ) : null}
    </Container>
  );
}

const styles = StyleSheet.create({
  scrollableContainer: {
    height: '60vh',
    overflowY: 'scroll',
  },
  button: {
    fontSize: '1em',
    padding: '0.5em 1em',
    fontWeight: 'bold',
  },
  select: {
    zIndex: 2,
  },
});

export default ManageProductOutliersView;
