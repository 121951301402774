import React, { useState } from 'react';

import classNames from 'classnames';
import { Col, Row } from 'react-bootstrap';
import { css, StyleSheet } from 'aphrodite';
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import pointerOnHover from 'lib/css/pointerOnHover';
import { Table } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import { BRANDS_FOR_QA } from 'lib/networking/endpoints';
import BrandQAViewTableRow from 'components/manage_brands/BrandQAViewTableRow';
import Container from 'components/shared/Container';
import { HUNDRED_PAGE_SIZE } from 'lib/constants';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import PaginationComponent from 'components/shared/PaginationComponent';
import useGet from 'lib/hooks/useGet';

function BrandQAView() {
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(HUNDRED_PAGE_SIZE);
  const {
    data: { brands, count } = {},
    refetch,
    loading,
  } = useGet(BRANDS_FOR_QA, {
    limit: itemsPerPage,
    offset: itemsPerPage * (currentPage - 1),
  });
  return (
    <Container>
      <div className="mb-3 d-flex justify-content-between">
        <h3 className="font-weight-bold">
          <FontAwesomeIcon
            className={classNames(pointerOnHover, 'mr-2')}
            icon={faArrowCircleLeft}
            onClick={() => history.push('/enrich-products/manage-brands')}
          />
          Verified Brands
        </h3>
      </div>
      <Row>
        <Col className="d-flex justify-content-start mt-3 ml-1">
          <span>{count} Brands found</span>
        </Col>
        <Col className={classNames(css(styles.pagination), 'd-flex justify-content-end')}>
          <PaginationComponent
            totalItems={count}
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            defaultSize
          />
        </Col>
      </Row>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Verified Brand Name</th>
              <th>Manufacturer Name</th>
              <th>Similar Brand Names</th>
              <th>Verified By</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {brands?.map(brand => (
              <BrandQAViewTableRow key={brand.id} brand={brand} refetch={refetch} />
            ))}
          </tbody>
        </Table>
      )}
    </Container>
  );
}

const styles = StyleSheet.create({
  pagination: {
    marginTop: '1rem',
    zIndex: 0,
  },
});

export default BrandQAView;
