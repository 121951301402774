import { BrandVerificationStatus } from 'lib/enums/BrandVerificationStatus';
import { ManufacturerVerificationStatus } from 'lib/enums/ManufacturerVerificationStatus';
// update along with app/lib/enums/dashboard_widget_types.py
const DashboardWidgetType = {
  VERIFIED_VENDOR_PRODUCTS: 'VERIFIED_VENDOR_PRODUCTS',
  VERIFIED_MANUFACTURER_PRODUCTS: 'VERIFIED_MANUFACTURER_PRODUCTS',
  VERIFIED_BRANDS: 'VERIFIED_BRANDS',
  VERIFIED_MANUFACTURERS: 'VERIFIED_MANUFACTURERS',
  TAGGED_IMAGES: 'TAGGED_IMAGES',
  ENRICHED_BRANDS: 'ENRICHED_BRANDS',
  VERIFICATION_PENDING_BRANDS: 'VERIFICATION_PENDING_BRANDS',
  VERIFICATION_PENDING_MANUFACTURERS: 'VERIFICATION_PENDING_MANUFACTURERS',
  UNVERIFIED_BRANDS_IN_DISTRIBUTOR_PARTNERS: 'UNVERIFIED_BRANDS_IN_DISTRIBUTOR_PARTNERS',
};

const DashboardWidgetTypeLabels = {
  [DashboardWidgetType.VERIFIED_VENDOR_PRODUCTS]: 'Verified Vendor Products',
  [DashboardWidgetType.VERIFIED_MANUFACTURER_PRODUCTS]: 'Verified Manufacturer Products',
  [DashboardWidgetType.VERIFIED_BRANDS]: 'Verified Brands',
  [DashboardWidgetType.VERIFIED_MANUFACTURERS]: 'Verified Manufacturers',
  [DashboardWidgetType.TAGGED_IMAGES]: 'Tagged Images',
  [DashboardWidgetType.ENRICHED_BRANDS]: 'Enriched Brands',
  [DashboardWidgetType.VERIFICATION_PENDING_BRANDS]: 'Verification Pending Brands',
  [DashboardWidgetType.VERIFICATION_PENDING_MANUFACTURERS]: 'Verification Pending Manufacturers',
  [DashboardWidgetType.UNVERIFIED_BRANDS_IN_DISTRIBUTOR_PARTNERS]:
    'Unverified Brands Among Distributor Partners',
};

const handleVerificationPendingBrandsClick = () => {
  const url =
    `/enrich-products/manage-brands` +
    `?verificationStatus=${BrandVerificationStatus.PENDING_VERIFICATION}`;
  window.open(url, '_blank');
};

const handleUnverifiedBrandsClick = () => {
  const url =
    `/enrich-products/manage-brands` +
    `?verificationStatus=${BrandVerificationStatus.UNVERIFIED}&isDPOnly=true`;
  window.open(url, '_blank');
};

const handleVerificationPendingManufacturersClick = () => {
  const url =
    `/enrich-products/manage-manufacturers` +
    `?verificationStatus=${ManufacturerVerificationStatus.PENDING_VERIFICATION}`;
  window.open(url, '_blank');
};

const WidgetClickHandlers = {
  [DashboardWidgetType.VERIFICATION_PENDING_BRANDS]: handleVerificationPendingBrandsClick,
  [DashboardWidgetType.VERIFICATION_PENDING_MANUFACTURERS]:
    handleVerificationPendingManufacturersClick,
  [DashboardWidgetType.UNVERIFIED_BRANDS_IN_DISTRIBUTOR_PARTNERS]: handleUnverifiedBrandsClick,
};

export { DashboardWidgetTypeLabels, WidgetClickHandlers };
export default DashboardWidgetType;
