import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Form, Modal } from 'react-bootstrap';
import Select from 'react-select';
import Swal from 'sweetalert2';

import { CONTENT_VERIFICATION_STATUS_OPTIONS } from 'lib/enums/ContentVerificationStatus';
import { DATA_STREAM_SYNC_JOB } from 'lib/networking/endpoints';
import { ProductStatusOptionsOfGlobalAttribute } from 'lib/enums/ProductStatus';
import usePost from 'lib/hooks/usePost';
import usePut from 'lib/hooks/usePut';

function CreateUpdateDataStreamSyncJob({
  onHide,
  dataStreamId,
  updateDataStreamSyncJobs,
  dataStreamSyncJob,
}) {
  const [newDataStreamSyncJob, setNewDataStreamSyncJob] = useState({
    destinationLocationUrl: dataStreamSyncJob ? dataStreamSyncJob.destination_location_url : '',
    cronExpression: dataStreamSyncJob ? dataStreamSyncJob.cron_expression : '',
    contentVerificationStatus: dataStreamSyncJob?.content_verification_status_filter || null,
    productStatus: dataStreamSyncJob?.product_status_filter || null,
  });

  const { postData: createNewDataStreamSyncJob, loading } = usePost(
    DATA_STREAM_SYNC_JOB,
    res => {
      Swal.fire({
        icon: 'success',
        text: 'Successfully created',
        title: 'Success',
        position: 'center',
      }).then(() => {
        updateDataStreamSyncJobs(res.data.data);
      });
    },
    () => {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while creating',
        title: 'Error',
        position: 'center',
      });
    },
  );

  const { putData: updateStreamSyncJob } = usePut(
    DATA_STREAM_SYNC_JOB,
    res => {
      Swal.fire({
        icon: 'success',
        text: 'Successfully updated',
        title: 'Success',
        position: 'center',
      }).then(() => {
        updateDataStreamSyncJobs(res.data.data, true);
      });
    },
    () => {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while updating',
        title: 'Error',
        position: 'center',
      });
    },
  );

  return (
    <>
      <Modal centered show={true} onHide={onHide} animation={false}>
        <Modal.Header>
          <h4>{dataStreamId ? 'Edit' : 'Add'} Sync Job For Stream</h4>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Location URL</Form.Label>
            <Form.Control
              onChange={e =>
                setNewDataStreamSyncJob({
                  ...newDataStreamSyncJob,
                  destinationLocationUrl: e.target.value,
                })
              }
              value={newDataStreamSyncJob.destinationLocationUrl}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Cron Expression</Form.Label>
            <Form.Control
              onChange={e =>
                setNewDataStreamSyncJob({
                  ...newDataStreamSyncJob,
                  cronExpression: e.target.value,
                })
              }
              value={newDataStreamSyncJob.cronExpression}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Filter Content Verified Products</Form.Label>
            <Select
              isClearable
              options={CONTENT_VERIFICATION_STATUS_OPTIONS}
              value={CONTENT_VERIFICATION_STATUS_OPTIONS.filter(
                op => op.value === newDataStreamSyncJob.contentVerificationStatus,
              )}
              onChange={op =>
                setNewDataStreamSyncJob({
                  ...newDataStreamSyncJob,
                  contentVerificationStatus: op?.value,
                })
              }
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Filter Active Products</Form.Label>
            <Select
              isClearable
              options={ProductStatusOptionsOfGlobalAttribute}
              value={ProductStatusOptionsOfGlobalAttribute.filter(
                op => op.value === newDataStreamSyncJob.productStatus,
              )}
              onChange={op =>
                setNewDataStreamSyncJob({
                  ...newDataStreamSyncJob,
                  productStatus: op?.value,
                })
              }
            />
          </Form.Group>
          <Button
            block
            disabled={loading}
            onClick={() => {
              if (dataStreamSyncJob) {
                updateStreamSyncJob({
                  data_stream_sync_job_id: dataStreamSyncJob.id,
                  destination_location_url: newDataStreamSyncJob.destinationLocationUrl,
                  cron_expression: newDataStreamSyncJob.cronExpression,
                  filters: {
                    content_verification_status_filter:
                      newDataStreamSyncJob.contentVerificationStatus || null,
                    product_status_filter: newDataStreamSyncJob.productStatus || null,
                  },
                });
              } else {
                createNewDataStreamSyncJob({
                  data_stream_id: dataStreamId,
                  destination_location_url: newDataStreamSyncJob.destinationLocationUrl,
                  cron_expression: newDataStreamSyncJob.cronExpression,
                  filters: {
                    content_verification_status_filter:
                      newDataStreamSyncJob.contentVerificationStatus,
                    product_status_filter: newDataStreamSyncJob.productStatus || null,
                  },
                });
              }
            }}
          >
            Submit
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
}

CreateUpdateDataStreamSyncJob.propTypes = {
  onHide: PropTypes.func,
  dataStreamId: PropTypes.number,
  updateDataStreamSyncJobs: PropTypes.func,
  dataStreamSyncJob: PropTypes.object,
};

export default CreateUpdateDataStreamSyncJob;
