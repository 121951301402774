import React from 'react';

import { Button, Table } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import Container from 'components/shared/Container';
import { SIMILAR_BRAND_INFO } from 'lib/networking/endpoints';

import useGet from 'lib/hooks/useGet';

function SimilarBrandsView() {
  const { similarBrandName } = useParams();
  const { data: { products } = {} } = useGet(SIMILAR_BRAND_INFO, {
    similar_brand_name: similarBrandName,
  });
  return (
    <Container>
      <div className="mb-3 d-flex justify-content-between">
        <h3 className="font-weight-bold"> {similarBrandName}</h3>
      </div>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Entity Type</th>
            <th>Entity Name</th>
            <th>SKU</th>
            <th>Product Name</th>
            <th>Pack Size</th>
            <th>Distributor Manufacturer Name</th>
            <th>View</th>
          </tr>
        </thead>
        <tbody>
          {products?.length > 0
            ? products?.map(product => (
                <tr key={product.id}>
                  <td>Distributor</td>
                  <td>{product?.global_product_vendor?.global_vendor?.name || '-'}</td>
                  <td>{product?.global_product_vendor?.vendor_sku || '-'}</td>
                  <td>{product?.global_product_vendor?.name || '-'}</td>
                  <td>{product?.global_product_vendor?.name || '-'}</td>
                  <td>{product?.global_product_vendor?.distributor_mfr_name || '-'}</td>
                  <td>
                    <Button
                      variant="link"
                      onClick={() =>
                        window.open(
                          `/global-product-detail/
                          ${product?.global_product_vendor?.global_product_id}
                          /${product?.global_product_vendor?.id}`,
                          '_blank',
                        )
                      }
                    >
                      View
                    </Button>
                  </td>
                </tr>
              ))
            : null}
        </tbody>
      </Table>
      <div className="d-flex justify-content-center">
        {!products?.length > 0 ? 'No products to display' : null}
      </div>
    </Container>
  );
}

export default SimilarBrandsView;
